import React, { useCallback, useEffect, useState } from "react";
// import RegisterModal from "./RegisterModal";
// import AppointmentModal from "./AppointmentModal";
import HomeAppointments from "./HomeAppointments";
import { getHomeAppointments, getHospitalAppointments } from "../../utils/appointmentUtils/appointmentUtils";
import HospitalAppiontments from "./HospitalAppointments";
import AppointmentTrackerModal from "./AppointmentTrackerModal/AppointmentTrackerModal";
import ListOfFlebos from "./ListOfFlebos";

const AppointmentsMainSection = () => {
  const [selected, setSelected] = useState(1);
  // const [open, setOpen] = useState(false);
  const [homeAppointments, setHomeAppointments] = useState([]);
  const [hospitalAppointments, setHospitalAppointments] = useState([]);
  // const [isRevisit, setIsRevisit] = useState(false);
  // const [isFollowup, setIsFollowup] = useState(false);
  // const [patientDetails, setPatientDetails] = useState(null);
  // const [mode, setMode] = useState("");
  const setHomeAppointmentsFn = useCallback(() => {
    getHomeAppointments().then(res => setHomeAppointments(res.data.data)).catch(err => console.error(err));
  },[]);
  const setHospitalAppointmentsFn = useCallback(() => {
    getHospitalAppointments().then(res => setHospitalAppointments(res.data.data)).catch(err => console.error(err));
  },[]);
  useEffect(() => {
    setHomeAppointmentsFn();
    setHospitalAppointmentsFn();
  }, [setHomeAppointmentsFn, setHospitalAppointmentsFn]);
    const [openTracker, setOpenTracker] = useState(false);
    const [openFlebos, setOpenFlebos] = useState(false);
    const [editData, setEditData] = useState(null);
  // const closeFollowAndRevisit = (value) => {
  //   setIsRevisit(value);
  //   setIsFollowup(value);
  //   setPatientDetails(null);
  //   setMode("");
  // }
  return (
    <>
      {/* {open && (
        <RegisterModal
          setOpen={setOpen}
          setDatasFn={setconsultationAppointmentsFn}
        />
      )}
      {patientDetails && (
        <AppointmentModal
          appointmentDetails={patientDetails}
          is_follow_up={isFollowup}
          is_re_visit={isRevisit}
          setOpen={closeFollowAndRevisit}
          setDatasFn={setconsultationAppointmentsFn}
          mode={mode}
        />
      )} */}
      {openFlebos && (
        <ListOfFlebos
          setOpen={setOpenFlebos}
          editData={editData}
          setAppointmentsFn={setHomeAppointmentsFn}
          setEditData={setEditData}
        />
      )}
      {openTracker && (
        <AppointmentTrackerModal
          setEditData={setEditData}
          editData={editData}
          setOpenTracker={setOpenTracker}
          setAppointmentsFn={selected === 1 ? setHomeAppointmentsFn : setHospitalAppointmentsFn}
        />
      )}
      <div className="flex flex-col w-full h-full">
        <div className="flex w-full justify-between items-center gap-3">
          <div className="flex gap-3">
            <button
              onClick={() => setSelected(1)}
              className={`flex px-3 border border-primary py-1 rounded-md capitalize ${
                selected === 1
                  ? "bg-primary text-white"
                  : "bg-white text-primary"
              }`}
            >
              Home
            </button>
            <button
              onClick={() => setSelected(2)}
              className={`flex px-3 border border-primary py-1 rounded-md capitalize ${
                selected === 2
                  ? "bg-primary text-white"
                  : "bg-white text-primary"
              }`}
            >
              Hospital
            </button>
            {/* <button
              onClick={() => setSelected(3)}
              className={`flex px-3 border border-primary py-1 rounded-md capitalize ${
                selected === 3
                  ? "bg-primary text-white"
                  : "bg-white text-primary"
              }`}
            >
              Lab
            </button> */}
          </div>
          {/* <div className="flex">
            <button
              // onClick={() => setOpen(true)}
              className={`flex px-3 border border-primary py-1 rounded-md bg-primary text-white`}
            >
              Appointment
            </button>
          </div> */}
        </div>
        {selected === 1 && (
          <HomeAppointments
            setFleboOpen={setOpenFlebos}
            setEditData={setEditData}
            setOpenTracker={setOpenTracker}
            appointments={homeAppointments}
            setAppointmentsFn={setHomeAppointmentsFn}
          />
        )}
        {selected === 2 && (
          <HospitalAppiontments
            setEditData={setEditData}
            setOpenTracker={setOpenTracker}
            appointments={hospitalAppointments}
            setAppointmentsFn={setHospitalAppointmentsFn}
          />
        )}
        {/* {
          // selected === 1 &&
          (
          <Consultation
            appointments={consultationAppointments}
            setAppointmentsFn={setconsultationAppointmentsFn}
            setIsRevisit={setIsRevisit}
            setIsFollowup={setIsFollowup}
            setPatientDetails={setPatientDetails}
            setMode={setMode}
            searchParams={{ patient_id, email, phone_number }}
          />
          )
        } */}
        {/* {selected === 2 && (
          <Lab
            appointments={labAppointments}
            setAppointmentsFn={setLabAppointmentsFn}
            setIsRevisit={setIsRevisit}
            setIsFollowup={setIsFollowup}
            setPatientDetails={setPatientDetails}
            setMode={setMode}
            searchParams={{ patient_id, email, phone_number }}
          />
        )} */}
      </div>
    </>
  );
};

export default AppointmentsMainSection;
