export const validateLoginForm = (initialState, setErr) => {
    const idPattern = /^.+$/;
    const passwordPattern = /^.{8,}$/

    if (!initialState.adminId.trim() || !idPattern.test(initialState.adminId.trim())) {
        setErr({ global: "Invalid Admin Id" });
        return;
    }

    if (!passwordPattern.test(initialState.password)) {
        setErr({ global: "Invalid Password" });
        return;
    }
    return true;
};