import { axiosInstance } from "../../api/config";

export const getPurchasedCards = async () => {
  try {
    const response = await axiosInstance.get(`/getcardpurchase`);
    return response;
  } catch (error) {
    throw error;
  }
}

function formatDate(inputDate) {
  const dateParts = inputDate.split("-");
  const year = dateParts[0];
  const month = dateParts[1].replace(/^0+/, ''); // Remove leading zeros
  const day = dateParts[2].replace(/^0+/, ''); // Remove leading zeros

  return `${month}/${day}/${year}`;
}

export const getConsultatoinAppointments = async (withPagination = false, search = '', page = 1, hospital_id ='', doctor_id= '', start_date = '', end_date = '') => {
  try {
    // console.log(hospital_id, doctor_id)
    let url = `/getallapppointments`;
    const params = [];
    if (withPagination && search) {
      params.push(`search=${encodeURIComponent(search)}`);
    }
    if (withPagination && page) {
      params.push(`page=${page}`);
    }
    if (withPagination && hospital_id) {
      params.push(`hospital_id=${hospital_id}`);
    }
    if (withPagination && doctor_id) {
      params.push(`doctor_id=${doctor_id}`);
    }
    if (withPagination && start_date) {
      params.push(`start_date=${formatDate(start_date)}`);
    }
    if (withPagination && end_date) {
      params.push(`end_date=${formatDate(end_date)}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getOnlineConsultatoinAppointments = async () => {
  try {
    const response = await axiosInstance.get(`/appointment/get_appointment`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addConsultationAppointment = async (initialState, hospital_id, doctor, time, slot, date,
  { haveCard, cardId, selectedDiscount, doctorReduceCredit, doctorUsingCredit, additionRate, reducedPrice, offerType, user_select_offers }
  , is_follow_up) => {
  try {
    const data = {
      ...initialState,
      hospital_id,
      doctor_id: doctor?._id,
      date: date?.date,
      dateObject: date,
      slot,
      time,
      is_follow_up,
      booking_type: "offline"
    };
    let requestData = {};
    if (haveCard && offerType === "discount") {
      requestData = {
        ...data,
        price: reducedPrice,
        user_card: cardId,
        user_using_discount: selectedDiscount,
        user_select_offers,
        if_user_using_discount: true,
        if_user_free_opd: false
      };
    } else if (haveCard && offerType === "free") {
      requestData = {
        ...data,
        price: reducedPrice,
        using_card: true,
        user_card: cardId,
        user_select_offers,
        if_user_free_opd: true,
        if_user_using_discount: false,
      };
    } else if (haveCard) {
      if (offerType === "points") {
        requestData = {
          ...data,
          price: reducedPrice,
          using_card: true,
          user_card: cardId,
          user_select_offers,
          if_user_free_opd: false,
          if_user_using_discount: false,
          adding_credit_points: doctorReduceCredit,
          using_credit_points: doctorUsingCredit,
        };
      } else {
        requestData = {
          ...data,
          price: reducedPrice,
          using_card: true,
          user_card: cardId,
          user_select_offers,
          if_user_free_opd: false,
          if_user_using_discount: false,
          adding_credit_points: Math.floor(reducedPrice / 100) * additionRate
        };
      }
    } else {
      requestData = { ...data, price: doctor?.price, };
    }
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };
    const url = `/offlineappointment/add_offlineappointment`;
    const response = await axiosInstance['post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const applyOffer = async (doctor,
  { haveCard, cardId, selectedDiscount, selectedPoints, selectedReducePoints, offerType , userId }
  ) => {
  try {
    let requestData = {};
    if (haveCard && offerType === "discount") {
      requestData = {
        actual_price: doctor?.price,
        card_id: cardId,
        user_id: userId,
        user_using_free_opd: false,
        user_using_discount: selectedDiscount,
      };
    } else if (haveCard && offerType === "free") {
      requestData = {
        actual_price: doctor?.price,
        card_id: cardId,
        user_id: userId,
        user_using_free_opd: true,
        user_using_discount: false,
      };
    } else if (haveCard && offerType === "points") {
      requestData = {
        actual_price: doctor?.price,
        card_id: cardId,
        user_id: userId,
        user_using_free_opd: false,
        user_using_discount: false,
        user_using_credits: selectedPoints,
      };
    } else {
      requestData = { price: doctor?.price };
    }
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };
    const url = `/usercard/add_usercard`;
    const response = await axiosInstance['post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const verifyOfferOtp = async (
  { responseDataId, otpVerify }, setErr
  ) => {
  try {
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };
    const url = `/usercardotpverify/${responseDataId}`;
    const response = await axiosInstance['post'](url, { otpVerify }, requestConfig);
    return response;
  } catch (error) {
    setErr({ offer: "Otp Not Matching" })
    throw error;
  }
}

export const verfiyConsultationBookingOtp = async (initialState, otp, setErr) => {
  try {
    const requestData = {
      visit_id: initialState.visit_id,
      otpVerify: otp
    }
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };
    const response = await axiosInstance.post(`/consultant/verify`, requestData, requestConfig);
    return response;
  } catch (error) {
    setErr({ global: "Otp Not Matching" })
    throw error;
  }
}

export const verfiyFollowupConsultationBookingOtp = async (initialState, otp, setErr) => {
  try {
    const requestData = {
      visit_id: initialState.visit_id,
      otpVerify: otp
    }
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };
    const response = await axiosInstance.post(`/consultant_followup/verify`, requestData, requestConfig);
    return response;
  } catch (error) {
    setErr({ global: "Otp Not Matching" })
    throw error;
  }
}


export const deleteApponintment = async (appointmentId) => {
  try {
    const response = await axiosInstance.delete(`/offlineappointment/delete_offlineappointment/${appointmentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getConsultationFollowupAppointments = async () => {
  try {
    const response = await axiosInstance.get(`/receptionconsultantfollowup`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addConsultationFollowupAppointments = async (initialState, doctor, time, slot, date, patient_id) => {
  try {
    const requestData = {
      name: initialState.name,
      phone_number: initialState.contact,
      email: initialState.email || '',
      doctor_id: doctor?._id,
      date: date.date,
      gender: initialState.gender,
      dateObject: date,
      slot,
      time,
      payment_status: initialState.status,
      patient_id
    };
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };
    const url = `/reception/create_consultantfollowup`;
    const response = await axiosInstance['post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteConsultationFollowupApponintment = async (appointmentId) => {
  try {
    const response = await axiosInstance.delete(`/reception/delete_consultantfollowup/${appointmentId}`);
    return response;
  } catch (error) {
    throw error;
  }
}