import React, { useCallback, useEffect, useState } from "react";
import { SearchInput } from "../Common";
import { useSelector } from "react-redux";
import ServicesAppointmentsTable from "./ServicesAppointmentsTable";
import { getServiceAppointments } from "../../utils/servicesUtils/servicesUtils";

const ServicesMainSection = () => {
  const user = useSelector((state) => state.userReducer.admin);
  const [leads, setLeads] = useState([]);
  const setDatasFn = useCallback(() => {
    getServiceAppointments()
      .then((res) => setLeads(res.data.data))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => setDatasFn(), [setDatasFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = leads?.filter((lead) => {
      const nameMatch = lead?.name
        ?.toLowerCase()
        .includes(searchQuery?.toLowerCase());
      const cNumberMatch = lead?.phone_number
        ?.toString()
        ?.toLowerCase()
        .includes(searchQuery?.toLowerCase());
      const locationMatch = lead?.location
        ?.toString()
        ?.toLowerCase()
        .includes(searchQuery?.toLowerCase());
      const serviceMatch = lead?.service_name
        ?.toString()
        ?.toLowerCase()
        .includes(searchQuery?.toLowerCase());
      return nameMatch || cNumberMatch || locationMatch || serviceMatch;
    });
    setFilteredData(filteredData);
  }, [leads, searchQuery]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDatasFn();
    }, 5 * 60 * 1000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [
    setDatasFn
  ]);
  return (
    <>
      <div className=" flex w-full items-center gap-5 flex-col">
        <div className="flex w-fit h-fit text-xl font-semibold">Service Appointments</div>
        <div className="flex w-full h-full justify-center items-center">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search By Name, Number"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full p-3 flex-col">
        <div className="flex w-full justify-start items-center h-fit">
          <span className="font-semibold text-base">Informations</span>
        </div>
        <ServicesAppointmentsTable
          user={user}
          datas={filteredData}
          setDatasFn={setDatasFn}
        />
      </div>
    </>
  );
};

export default ServicesMainSection;
