import { axiosAuthorized } from "../../api/config";

export const getRegions = async () => {
  try {
    const response = await axiosAuthorized.get(`/labregion/get_labregion`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addRegion = async (initialState, lag_region_icon, id) => {
  try {
    const requestData = {
      ...initialState,
      lag_region_icon
    };
    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/labregion/update_labregion/${id}`
      : `/labregion/add_labregion`;
    
    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteRegion = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/labregion/delete_labregion/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getFlebo = async () => {
  try {
    const response = await axiosAuthorized.get(`/fabloregion/get_fabloregion`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addFlebo = async (initialState, lab_region, fablo_profile, id) => {
  try {
    lab_region = lab_region?.map((item) => item?._id);
    const requestData = {
      ...initialState,
      lab_region,
      phone_number: Number.parseInt(initialState.phone_number),
      fablo_profile
    };
    const requestConfig = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const url = id
      ? `/fabloregion/update_fabloregion/${id}`
      : `/fabloregion/add_fabloregion`;
    
    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteFlebo = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/fabloregion/delete_fabloregion/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getFleboByRegion = async (id) =>{
  try {
    const response = await axiosAuthorized.get(`/fablobylabregion/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}