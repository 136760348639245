import React from "react";
import { AppointmentsMainSection } from "../../components/LabAppointments";

const LabAppointments = () => {
  return (
    <div className="w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative">
      <AppointmentsMainSection />
    </div>
  );
};

export default LabAppointments;
