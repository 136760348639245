import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { IoSearchOutline } from "react-icons/io5";
import { useCallback } from "react";
import { useEffect } from "react";
import { getFleboByRegion } from "../../utils/fleboUtils/fleboUtils";
import { updateFlebo } from "../../utils/appointmentUtils/appointmentUtils";
import { toast } from "react-toastify";

const ListOfFlebos = ({
  setOpen,
  editData = null,
  setEditData = () => {},
  setAppointmentsFn = () => {},
}) => {
  const [flebos, setFlebos] = useState([]);
  const setFlebosFn = useCallback(() => {
    getFleboByRegion(editData?.lab_region?._id)
      .then((res) => setFlebos(res.data.data))
      .catch((error) => console.error(error));
  }, [editData?.lab_region?._id]);
  useEffect(() => {
    setFlebosFn();
  }, [setFlebosFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterdFlebos, setFilteredFlebos] = useState([]);
  useEffect(() => {
    const filteredData = flebos?.filter((flebo) => {
      const idMatch = flebo?.fablo_id
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      return idMatch;
    });
    setFilteredFlebos(filteredData);
  }, [flebos, searchQuery]);
  const [selected, setSelected] = useState({ _id: editData?.fablo_region?._id || null });
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState({ global: ""});
  const handleSubmit = (e) => {
    e.preventDefault();
      setErr({ global: "" });
      setLoading(true);
      updateFlebo(selected?._id, editData?._id)
        .then((res) => {
          setAppointmentsFn();
          toast(`Fleb Updated`, {
            type: "success",
            autoClose: 3000,
          });
        })
        .catch((err) => {
          console.error(err);
          toast(`Fleb Update Failed`, {
            type: "error",
            autoClose: 3000,
          });
        })
        .finally(() => setLoading(false));
  };
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex  w-full max-w-screen-md overflow-y-auto max-h-screen  border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"List of Flebos"}</span>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </button>
        </div>
        <div className="flex w-full h-fit flex-col gap-5">
          <div className="relative flex flex-col gap-0.5">
            <span className="absolute inset-y-0 left-0 top-5 flex items-center pl-2">
              <IoSearchOutline fontSize={23} />
            </span>
            <label
              htmlFor={"List Of Flebos"}
              className="text-[14px] text-shadow-black text-white"
            >
              {"List Of Flebos"}
            </label>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              className="appearance-none rounded-md w-full bg-white border border-gray-300 py-2 pl-10 pr-4 text-gray-700 leading-tight focus:outline-none focus:border-primary"
              placeholder="Search By ID"
            />
          </div>
          <div className="flex gap-5 flex-wrap min-h-[150px] max-h-[100px] overflow-y-scroll px-5 m-3 ">
            {filterdFlebos.length > 0 ? (
              filterdFlebos.map((item) => (
                <div
                  key={item._id}
                  onClick={() => {
                    setSelected({ _id: item?._id });
                  }}
                  className={`flex px-2 py-1 cursor-pointer w-fit h-fit gap-2  border rounded-md items-center ${
                    selected?._id === item?._id
                      ? `bg-white text-primary border-primary`
                      : `text-white`
                  }`}
                >
                  <div className={`flex w-7 h-7`}>
                    <img
                      src={
                        item?.fablo_profile instanceof File
                          ? URL.createObjectURL(item?.fablo_profile)
                          : item?.fablo_profile
                      }
                      alt={"service_image"}
                      className={`rounded-full ${
                        item?.fablo_profile ? "flex" : "hidden"
                      }`}
                    />
                  </div>
                  <span>{item?.fablo_id}</span>
                </div>
              ))
            ) : (
              <div className="flex h-full w-full justify-center items-center text-white">
                No Flebos Found!
              </div>
            )}
          </div>
          {selected.hospital_id && (
            <div className="flex flex-col w-full gap-5">
              <div className="flex w-full h-fit justify-between gap-5">
                <div className="flex flex-col w-full gap-0.5 ">
                  <label
                    htmlFor={""}
                    className=" text-[14px] text-shadow-black text-white "
                  >
                    {`${selected.hospital_id.hospital_name} Quotation`}
                    <span className="text-red-600">*</span>
                  </label>
                  <div
                    className=" relative flex flex-col justify-center items-end "
                    onClick={() => setErr({ hospital: "" })}
                  >
                    <input
                      type="text"
                      value={selected.hospital_quotation}
                      onChange={(e) => {
                        setSelected({
                          ...selected,
                          hospital_quotation: e.target.value,
                        });
                      }}
                      placeholder={`${selected.hospital_id.hospital_name} Quotation`}
                      className="border  p-2 text-[14px] w-full rounded-md outline-none shadow-md "
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full gap-0.5 ">
                <label
                  htmlFor={""}
                  className=" text-[14px] text-shadow-black text-white "
                >
                  {`${selected.hospital_id.hospital_name} remarks`}
                </label>
                <div
                  className=" relative flex flex-col justify-center items-end "
                  onClick={() => setErr({ hospital: "" })}
                >
                  <textarea
                    value={selected.remarks}
                    onChange={(e) => {
                      setSelected({
                        ...selected,
                        remarks: e.target.value,
                      });
                    }}
                    placeholder={`${selected.hospital_id.hospital_name} Remarks`}
                    className="border  p-2 text-[14px] w-full min-h-[100px] rounded-md outline-none shadow-md "
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListOfFlebos;
