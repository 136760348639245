import React from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

const Pagination = ({ current = 1, total = 1, onPageChange }) => {
  if (total <= 1) return null;
  
  const handlePrev = () => {
    if (current > 1) {
      onPageChange(current - 1);
    }
  };

  const handleNext = () => {
    if (current < total) {
      onPageChange(current + 1);
    }
  };

  const generatePageNumbers = () => {
    const pages = [];
    if (total <= 6) {
      for (let i = 1; i <= total; i++) {
        pages.push(i);
      }
    } else {
      if (current > 4) {
        pages.push(1, 2, 3, "...");
        if (current > 4 && current < total - 3) {
          pages.push(current - 1, current, current + 1, "...");
        }
        pages.push(total - 2, total - 1, total);
      } else {
        pages.push(1, 2, 3, 4, 5, "...");
        pages.push(total);
      }
    }
    return pages;
  };

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={handlePrev}
          disabled={current === 1}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:bg-gray-300 disabled:text-gray-600 disabled:cursor-not-allowed"
        >
          Previous
        </button>
        <button
          onClick={handleNext}
          disabled={current === total}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:bg-gray-300 disabled:text-gray-600 disabled:cursor-not-allowed"
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-center">
        {/* <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{current}</span> to{" "}
            <span className="font-medium">{total}</span> of{" "}
            <span className="font-medium">{total}</span> results
          </p>
        </div> */}
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={handlePrev}
              disabled={current === 1}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-300 disabled:text-gray-600 disabled:cursor-not-allowed"
            >
              <span className="sr-only">Previous</span>
              <AiOutlineLeft className="h-5 w-5" aria-hidden="true" />
            </button>
            {generatePageNumbers().map((page, index) =>
              page === "..." ? (
                <span
                  key={`${page}-${index}`}
                  className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900"
                >
                  ...
                </span>
              ) : (
                <button
                  key={`${page}-${index}`}
                  onClick={() => onPageChange(page)}
                  className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                    page === current
                      ? "bg-primary text-white"
                      : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  } focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary`}
                >
                  {page}
                </button>
              )
            )}
            <button
              onClick={handleNext}
              disabled={current === total}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:bg-gray-300 disabled:text-gray-600 disabled:cursor-not-allowed"
            >
              <span className="sr-only">Next</span>
              <AiOutlineRight className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
