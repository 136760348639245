import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { IoSearchOutline } from "react-icons/io5";
import { useCallback } from "react";
import { useEffect } from "react";
import { getHospitals } from "../../utils/hospitalUtils/hospitalUtils";

const LisOfHospitals = ({ setOpen, selected, setSelected }) => {
  const [hospitals, setHospitals] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState(selected || null);
  const setHospitalsFn = useCallback(() => {
    getHospitals()
      .then((res) => setHospitals(res.data.data || []))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setHospitalsFn();
  }, [setHospitalsFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterdHospitals, setFilteredHospitals] = useState([]);
  useEffect(() => {
    const filteredData = hospitals?.filter((hospital) => {
      const nameMatch = hospital?.hospital_name
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      return nameMatch;
    });
    setFilteredHospitals(filteredData);
  }, [hospitals, searchQuery]);
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex  w-full max-w-screen-md overflow-y-auto max-h-screen  border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"List of Hospitals"}</span>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
            }}
          >
            <GrFormClose fontSize={16} />
          </button>
        </div>
        <div className="flex w-full h-fit flex-col gap-5">
          <div className="relative flex flex-col gap-0.5">
            <span className="absolute inset-y-0 left-0 top-5 flex items-center pl-2">
              <IoSearchOutline fontSize={23} />
            </span>
            <label
              htmlFor={"List Of Hospitals"}
              className="text-[14px] text-shadow-black text-white"
            >
              {"List Of Hospitals"}
            </label>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              className="appearance-none rounded-md w-full bg-white border border-gray-300 py-2 pl-10 pr-4 text-gray-700 leading-tight focus:outline-none focus:border-primary"
              placeholder="Search By Name"
            />
          </div>
          <div className="flex gap-5 flex-wrap min-h-[200px] max-h-[200px] overflow-y-scroll px-5 m-3 ">
            {filterdHospitals?.length > 0 ? (
              filterdHospitals?.map((item) => (
                <div
                  key={item._id}
                  onClick={() => {
                    setSelectedHospital(item);
                  }}
                  className={`flex px-2 py-1 cursor-pointer w-fit h-fit  border rounded-md ${
                    selectedHospital?._id === item?._id
                      ? `bg-white text-primary border-primary`
                      : `text-white`
                  }`}
                >
                  {item?.hospital_name}
                </div>
              ))
            ) : (
              <div className="flex h-full w-full justify-center items-center text-white">
                No Hospitals Found!
              </div>
            )}
          </div>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
          {selectedHospital && (
            <div className=" flex flex-col w-full gap-0.5 ">
              <button
                className="w-full border border-primary py-1 rounded-md  text-white bg-primary text-shadow-black flex justify-center items-center"
                onClick={() => {
                  setSelected(null);
                  setSelectedHospital(null);
                }}
              >
                Clear
              </button>
            </div>
          )}
          {selectedHospital && (
            <div className=" flex flex-col w-full gap-0.5 ">
              <button
                className="w-full border border-primary py-1 rounded-md  text-white bg-primary text-shadow-black flex justify-center items-center"
                onClick={() => {
                  setSelected(selectedHospital);
                  setOpen(false);
                }}
              >
                Submit
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LisOfHospitals;
