import { Layout } from './layout';
import { Route, Routes } from 'react-router-dom';
import { Appointments, LabAppointments, Leads, Login, ServicesAppointments } from './pages';
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import { saveUser } from "./redux/userSlice";
import { AuthProtected, UserProtected } from './components/ProtectedRoutes/UserProtected';

function App() {
  const dispatch = useDispatch();
  const saveUserFromLocalStorage = useCallback(() => {
    const admin = JSON.parse(localStorage.getItem("admin"));
    if (admin) {
      dispatch(saveUser(admin));
    }
  }, [dispatch]);
  useEffect(() => {
    saveUserFromLocalStorage();
  }, [saveUserFromLocalStorage]);
  // const user = useSelector((state) => state.userReducer.admin);
  return (
    <Routes>
      <Route path="/" element={<UserProtected element={<Layout />} />}>
        <Route index={true} element={<Appointments />} />
        <Route path="/leads" element={<Leads />} />
        <Route path="/lab-appointments" element={<LabAppointments />} />
        <Route path="/services-appointments" element={<ServicesAppointments />} />
      </Route>
      <Route path="/login" element={<AuthProtected element={<Login />} />} />
    </Routes>
  );
}

export default App;
