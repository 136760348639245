import React, { useMemo } from "react";
import { NoDataFound } from "../../Common";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { deleteApponintment } from "../../../utils/bookConsultation/ConsultationUtils";
import Table from "../../Common/Table/Table";

const Consultation = ({
  appointments,
  setAppointmentsFn,
  setIsRevisit,
  setIsFollowup,
  setPatientDetails,
  setMode,
  currentPage,
  limit = 10,
}) => {
  const startIndex = (currentPage - 1) * limit + 1; 
  const headings = useMemo(
    () => [
      "SL No",
      "Type",
      "Booking",
      "Name",
      "P-ID",
      "V-ID",
      "Phone",
      "Email",
      "Date",
      "Time",
      "BookedTime",
      "Hospital",
      "Doctor",
      "Status",
      "Revisit",
      "New Follow-up",
      "Delete",
    ],
    []
  );
  const delAppointment = (appointmentId) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
    deleteApponintment(appointmentId)
      .then((res) => setAppointmentsFn())
      .catch((err) => console.log(err));
  };
  const handleRevisit = (data) => {
    setMode("consultancy");
    setIsRevisit(true);
    setIsFollowup(true);
    setPatientDetails({
      ...data,
      is_follow_up: true,
      is_re_visit: true,
      doctor_id: data?.doctor_id?.[0] || data?.doctor_id,
    });
  };
  const handleFollowUp = (data) => {
    setMode("consultancy");
    setIsFollowup(true);
    setPatientDetails({
      ...data,
      is_follow_up: true,
      patient_id: data?.patient_id?.[0] || data?.patient_id,
    });
  };
  return (
    <div className="h-full  w-full mt-5 ">
      {appointments?.length > 0 ? (
        <Table headings={headings}>
          {appointments?.map((data, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 capitalize">
                  {startIndex + index}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {data?.is_follow_up ? "Follow-up" : "Initial"}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {data?.booking_type}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {data?.patient_id?.name}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {data?.patient_id?.patient_id}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 ">{data?.visit_id}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 ">
                  {data?.patient_id?.phone_number}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {data?.patient_id?.email_id}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 ">{data?.date}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 ">{data?.time}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900">
                  {data?.createdAt
                    ? new Date(data.createdAt).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                        timeZone: "UTC",
                      })
                    : "N/A"}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 ">
                  {data?.hospital_id?.hospital_name}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 ">
                  {data?.doctor_id?.name}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm text-gray-900 ">
                  {data?.payment_status}
                </div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <button
                  className="flex justify-center items-center gap-2 text-xs border rounded-md bg-green-600 text-white p-2"
                  onClick={() => {
                    handleRevisit(data);
                  }}
                >
                  Revisit
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <button
                  className="flex justify-center items-center gap-2 text-xs border rounded-md bg-blue-600 text-white p-2"
                  onClick={() => {
                    handleFollowUp(data);
                  }}
                >
                  New Follow-up
                </button>
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <div
                  className="text-sm text-gray-900 capitalize cursor-pointer"
                  onClick={() => {
                    delAppointment(data?._id);
                  }}
                >
                  <MdOutlineDeleteOutline color="red" />
                </div>
              </td>
            </tr>
          ))}
        </Table>
      ) : (
        <NoDataFound data={"appointments"} />
      )}
    </div>
  );
};

export default Consultation;
