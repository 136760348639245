import React from "react";
import { LeadsMainSection } from "../../components/LeadsComponents";

const Leads = () => {
  return (
    <div className="w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative">
        <LeadsMainSection/>
    </div>
  );
};

export default Leads;
